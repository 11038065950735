import { ethers } from "ethers";
import ethUtil from '@/utils/eth-utils'
const { utils } = ethers;
import { BigNumber } from "@ethersproject/bignumber";
const log = require('debug')('store:wallet');

import detectEthereumProvider from '@metamask/detect-provider';
import { erc20Artifact } from "@/contracts";
// let { erc20Artifact } = Contracts;
import ProjectConstants from '@/config/constants';

const ROPSTEN_ID = '0x3';
const BSC_ID = '0x38';
const BSC_TESTNET_ID = '0x61';
const HARDHAT_ID = '0x7a69';

let network = ProjectConstants.network;
let DAPP_CHAIN_ID = ProjectConstants.chainId;

const KEY_CONNECTED = 'connected';

const provider = window.ethereum;
let hasAccountRequest = false;    // true if connect wallet request 
let walletProvider = null;
let walletConnected = false;       // true, if user actually try to connected. false, if user HAS disconnected.

const _knownTokens = [
    {
        contract: null,             // to be filled
        address: '0x55d398326f99059ff775485246999027b3197955',
        symbol: 'USDT',
        name: 'BSC-USD',
        decimals: 18,
        totalSupply: BigNumber.from(4_000_000_000),     // NOT important
        balance: ''
    },
    {
        contract: null,             // to be filled
        address: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        symbol: 'BTCB',
        name: 'BTCB Token',
        decimals: 18,
        totalSupply: BigNumber.from(21_000_000),     // NOT important
        balance: ''
    }
];

export const state = {
    ethereumDetected: false,
    connected: false,
    address: '',
    chainId: 0,
    balance: '',
    tokens: [],         // { contract, address, symbol, name, decimals, totalSupply, balance }
}

function _findToken(symbol) {
    for (let i=0; i<state.tokens.length; i++) {
        if (state.tokens[i].symbol == symbol) {
            return state.tokens[i];
        }
    }
    return null;
}
function _findTokenByAddress(addr) {
    for (let i=0; i<state.tokens.length; i++) {
        if (state.tokens[i].address == addr) {
            return state.tokens[i];
        }
    }
    return null;
}
function _findKnownTokenByAddress(addr) {
    for (let i=0; i<_knownTokens.length; i++) {
        if (_knownTokens[i].address == addr) {
            return _knownTokens[i];
        }
    }
    return null;
}


export const getters = {
    getToken: (state) => (symbol) => {
        return _findToken(symbol);
    },

    getTokenBalance: (state) => (symbol) => {
        // log(`== wallet : getTokenBalance('${symbol}')`)
        let token = _findToken(symbol)
        if (token) {
            log(`== wallet : getTokenBalance('${symbol}') , token found ${token.balance}`)
            return token.balance;
        }
        return '0'
    }
}

export const mutations = {
    SET_DETECTED(state, value) {
        state.ethereumDetected = value;
    },
    SET_CONNECTED(state, value) {
        state.connected = value;
        if (value == false) state.address = '';
    },
    SET_ADDRESS(state, addr) {
        state.address = addr;
    },
    SET_BALANCE(state, balance) {
        state.balance = balance;
    },
    SET_CHAIN_ID(state, chainId) {
        state.chainId = chainId;
    },
    SET_ACCOUNT(state, {address, balance }) {
        state.address = address;
        state.balance = balance;

        walletConnected = true;
        localStorage.setItem(KEY_CONNECTED, '1');
    },
    RESET_WALLET(state) {
        state.connected = false
        state.address = ''
        state.balance = ''
        state.tokens = []
    
        walletConnected = false;
        localStorage.setItem(KEY_CONNECTED, '0');
    },
    ADD_TOKEN(state, token) {
        state.tokens.push(token);
    },
    UPDATE_TOKEN_BALANCE(state, { symbol, balance }) {
        let token = _findToken(symbol);
        if (token) {
            token.balance = balance;
        }
    }
}

export const actions = {
    async init({ commit, state, dispatch }) {
        // log('== wallet : init')

        const detectedProvider = await detectEthereumProvider();

        const anyProvider = new ethers.providers.Web3Provider(window.ethereum, "any");
        anyProvider.on("network", onNetworkChanged);

        if (detectedProvider) {
            // log('== wallet : Ethereum detected!')

            // check if wallet connected before.
            let hasConnected = localStorage.getItem(KEY_CONNECTED);
            if (hasConnected && hasConnected == '1') {
                walletConnected = true;
            }
            else {
                walletConnected = false;
            }

            commit('SET_DETECTED', true);

            detectedProvider.on("accountsChanged", onAccountChanged);
            detectedProvider.on("chainChanged", onChainChanged);
            detectedProvider.on("disconnect", onDisconnected);

            // log('== wallet init : waiting event, connection state = %s', detectedProvider.isConnected())
            if (detectedProvider.isConnected()) {
                onWalletAvailable();
            }

            detectedProvider.on("connect", onWalletAvailable);
        } else {
            // if the provider is not detected, detectEthereumProvider resolves to null
            // TODO: 
            // console.error('Please install MetaMask!')
        }

        async function onNetworkChanged(newNetwork, oldNetwork) {
            if (oldNetwork) {
                // window.location.reload();
            }
        }

        async function onWalletAvailable(connectionInfo) {
            // log('== ethereum : connect')

            if (provider.isConnected()) {
                try {
                    let chainId = await provider.request({
                        method: 'eth_chainId'
                    })

                    onChainChanged(chainId);
                }
                catch (e) {
                    log('onWalletAvailable-error : %O', e)
                }
            }
        }

        async function onChainChanged(chainId) {
            log('== ethereum : chainChanged')

            setChain(commit, chainId);
        }

        async function onAccountChanged(accounts) {
            // log('== ethereum : accountChanged')

            setAccounts(commit, accounts);
        }

        function onDisconnected(error) {
            // log('== ethereum : disconnect : %O', error)

            resetWallet(commit)
        }
    },
    setConnected({ commit }, { isConnected, addr }) {
        // log("Wallet setConnected : %s , address = %s", isConnected, addr)

        if (isConnected) {
            commit('SET_CONNECTED', true)
            if (addr) commit('SET_ADDRESS', addr);
        }
        else {
            commit('SET_CONNECTED', false);
            commit('SET_ADDRESS', '');
        }
    },
    async connect({ commit }) {
        log('Connect wallet called : chainId = %s', state.chainId);

        walletConnected = true;
        localStorage.setItem(KEY_CONNECTED, '1');
        hasAccountRequest = true

        if (state.chainId == DAPP_CHAIN_ID) {
            // already connected and selected

            // let accounts = await provider.request({ method: "eth_accounts" });
            // setAccounts(commit, accounts);

            // if (accounts.length == 0 && needRequest) {
            //     await requestAccounts()
            // }
            // return

            setChain(commit, state.chainId);
            return;
        }

        log('--- calling switch chain !!')

        // change chain first
        // after chain changed, request wallet again
        await switchChain(DAPP_CHAIN_ID)
    },
    async disconnect({ commit }) {

        log('--- action disconnect');

        walletConnected = false;
        localStorage.setItem(KEY_CONNECTED, '0');
        resetWallet(commit);
    },
    async updateBalance({ commit }) {
        updateBalance(commit);
    },
    async updateTokenBalance({commit}) {
        if (state.address > '') {
            for (let i=0; i<state.tokens.length; i++) {
                let nBalance = await state.tokens[i].contract.balanceOf(state.address);
                let balance = utils.formatUnits(nBalance, state.tokens[i].decimals);
                commit('UPDATE_TOKEN_BALANCE', { symbol: state.tokens[i].symbol, balance });

                log(`== wallet : token[${state.tokens[i].symbol}] balance = %s`, state.tokens[i].balance)
            }
        }
    },
    async addToken({ commit }, address) {
        if (_findTokenByAddress(address)) {
            log('addToken() : Address already exists');
            return;
        }

        let walletProvider = new ethers.providers.Web3Provider(window.ethereum);
        if (state.chainId != ProjectConstants.chainId) {
            walletProvider = new ethers.providers.JsonRpcProvider( ProjectConstants.rpcUrl )
        }

        let erc20 = new ethers.Contract(
          address,
          erc20Artifact.abi,
          walletProvider
        );
        
        // log('addToken() : ERC20 -- token : %O', erc20)
        let decimals = 18;
        let balance = '';

        if (state.address > '') {
            decimals = await erc20.decimals();
            let nBalance = await erc20.balanceOf(state.address);
            balance = utils.formatUnits(nBalance, decimals);            
        }

        let token = _findKnownTokenByAddress(address);
        if (token) {
            token.contract = erc20;
            token.balance = balance;

            // log('Add Known Token %s', token.symbol);
        }
        else {
            token = {
                contract: erc20,
                address,
                decimals,
                symbol: await erc20.symbol(),
                name: await erc20.name(),
                totalSupply: await erc20.totalSupply(),
                balance
            }
        }

        commit('ADD_TOKEN', token);
    },
}

// --------------------------------------------------------------------
//   onBeforeUnmount(() => {
//     if (provider) {
//       provider.removeListener("connect", onWalletAvailable);
//       provider.removeListener("accountsChanged", onAccountChanged);
//       provider.removeListener("chainChanged", onChainChanged);
//       provider.removeListener("disconnect", disconnected);
//     }
//   })

async function setChain(commit, chainId) {
    // log("== wallet: setChainId: %s", chainId);

    // state.chainId = chainId;
    commit('SET_CHAIN_ID', chainId);

    // user disconnected or initial state
    if (walletConnected == false) {
        log('============ skip setChain : walletConnected = %s', walletConnected);
        if (! hasAccountRequest) return;
    }

    log('--- Chain Changed : Chain Equals = %s, Has Request = %s', chainId == DAPP_CHAIN_ID, hasAccountRequest);
    let needRequest = hasAccountRequest;
    hasAccountRequest = false

    // check
    if (chainId == DAPP_CHAIN_ID) {
        try {
            let accounts = await provider.request({ method: "eth_accounts" });

            // already connected and selected
            setAccounts(commit, accounts);

            if (accounts.length == 0 && needRequest) {
                await requestAccounts()
            }
        }
        catch (e) {
            log('chainChanged-error : %O', e)
        }
    }
    else {
        resetWallet(commit);
    }    
}

async function setAccounts(commit, accounts) {
    // log("== wallet : setAccounts() : %O", accounts);

    if (null == walletProvider) {
        // log('== wallet : setAccount()')
        walletProvider = new ethers.providers.Web3Provider(window.ethereum);
    }

    if (accounts.length > 0) {
        log('accounts : %O', accounts);

        let address = accounts[0];
        let gwei = await walletProvider.getBalance(address);

        commit('SET_ACCOUNT', {
            address,
            // signer: walletProvider.getSigner(),
            balance: utils.formatUnits(gwei, "ether"),
            ready: true
        });
    } else {
        resetWallet(commit);
    }
}

async function updateBalance(commit) {
    // log("--- updateAccount :");

    if (null == walletProvider) {
        log('== wallet : updateBalance(), new eth provider ')
        walletProvider = new ethers.providers.Web3Provider(window.ethereum);
    }

    if (state.address > '') {
        let address = state.address;
        let gwei = await walletProvider.getBalance(address);
        commit('SET_BALANCE', utils.formatUnits(gwei, "ether"));
    }
}

function resetWallet(commit) {
    commit('RESET_WALLET');
}

async function switchChain(chainId) {
    if (!provider) {
        window.alert("Install wallet (Metamask) first");
        return false;
    }

    if (state.chainId != chainId) {
        log("--- switching to chain : %s,  old chain = %s ", chainId, state.chainId);

        try {
            let res = await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainId }],
            });
            // log('Chain req response : %O', res)
            return true;

        } catch (switchError) {
            if (switchError.code === 4902) {
                // EIP-3085
                if (chainId == '0x38') ethUtil.addBSC();
                else if (chainId == '0x61') ethUtil.addBSCTestnet();
                else ethUtil.addChain(chainId, ProjectConstants.networkDisplay, 
                    ProjectConstants.coinName,
                    [ ProjectConstants.rpcUrl ],
                    [ ProjectConstants.explorerUrl ], []);
        
            } else {
                if (switchError.code === 4001) {
                    log('User rejected switch chain to : %s', chainId)
                }
                else {
                    log("Ethereum change network error : %O", switchError);
                }
            }
            return false;
        }
    }
    else {
        log("--- switchChain(chainId) : chainId =  %s", chainId);
        return true
    }
}

async function requestAccounts() {
    try {
        let accounts = await provider.request({
            method: "eth_requestAccounts",
        });

        log('===== requestAccounts() .. %O', accounts)

        // accountChanged(accounts)
    } catch (e) {
        if (e.code === 4001) {
            log('User rejected to connect wallet')
        }
        else {
            log('requestAccounts : %O', e);
        }
    }
}

