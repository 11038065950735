
const ProjectConstants = {
    // General constants
    // network name in hardhat.config.js (used for ethers.getDefaultProvider())
    network: process.env.VUE_APP_NETWORK || 'bsc-mainnet',
    urlPath: 'https://poct.btcth.finance/',     // should end with '/'
    // networkDisplay: 'BSC Testnet',
    // chainId: '0x38',        // BNB
    // coinName: 'BNB',
    // rpcUrl: 'https://bsc-dataseed.binance.org/',
    // explorerUrl: 'https://bscscan.com',

    // Smart Contracts
    schedulePresaleStart: new Date('2021-11-01 00:00 +00'),
    schedulePresaleEnd: new Date('2024-01-01 00:00 +00'),
    scheduleTGE: new Date('2022-01-15 00:00 +00'),
    scheduleMining: new Date('2022-02-01 00:00 +00'),

    tokenName: 'POCT',
    tetherTokenName: 'USDT',
    rewardTokenName: 'BTCPegged',
    contractNames: {
        stakeToken: 'POCT',
        tetherToken: 'USDT',
        rewardToken: 'BTCPegged',
        crowdSale: 'POCTCrowdSale',
        miningFarm: 'POCTFarm',
    },

    // SNS Accounts
    twitter: 'https://twitter.com/BTCTH_official',
    telegram_chat: 'https://t.me/BTCTH_chat',
    telegram_ann: 'https://t.me/BTCTH_ann',
    medium: 'https://medium.com/@btcth_official',
    facebook: '',
    github: 'https://github.com/btcth',
}


if (ProjectConstants.network == 'bsc-mainnet') {
    ProjectConstants.networkDisplay = 'Binance Smart Chain Mainnet';
    ProjectConstants.chainId = '0x38';
    ProjectConstants.coinName = 'BNB';
    ProjectConstants.rpcUrl = 'https://bsc-dataseed.binance.org/';
    ProjectConstants.explorerUrl = 'https://bscscan.com';
    // POCT Information
    ProjectConstants.contractNames = {
        stakeToken: 'POCT',
        tetherToken: 'USDT',
        rewardToken: 'BTCB',
        crowdSale: 'POCTCrowdSale',
        miningFarm: 'POCTFarm',
    }

    // BTCTH Information
    // ProjectConstants.contractNames = {
    //     stakeToken: 'BTCTH',
    //     tetherToken: 'USDT',
    //     rewardToken: 'BTCB',
    //     crowdSale: 'BTCTHCrowdSale',
    //     miningFarm: 'BTCTHFarm',
    // }
}
else if (ProjectConstants.network == 'bsc-testnet') {
    ProjectConstants.networkDisplay = 'BSC Testnet';
    ProjectConstants.chainId = '0x61';
    ProjectConstants.coinName = 'BNB';
    ProjectConstants.rpcUrl = 'https://data-seed-prebsc-1-s1.binance.org:8545/';
    ProjectConstants.explorerUrl = 'https://testnet.bscscan.com';
    ProjectConstants.contractNames = {
        stakeToken: 'POCT',
        tetherToken: 'USDT',
        rewardToken: 'BTCPegged',
        crowdSale: 'POCTCrowdSale',
        miningFarm: 'POCTFarm',
    }
}
else if (ProjectConstants.network == 'ropsten') {
    ProjectConstants.networkDisplay = 'Ropsten';
    ProjectConstants.chainId = '0x3',        // Ropsten
    ProjectConstants.coinName = 'ETH';
    ProjectConstants.rpcUrl = 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
    ProjectConstants.explorerUrl = 'https://ropsten.etherscan.io';
}
else if (ProjectConstants.network == 'localhost') {
    ProjectConstants.networkDisplay = 'Localhost';
    ProjectConstants.chainId = '0x7a69',        // Hardhat
    ProjectConstants.coinName = 'ETH';
    ProjectConstants.rpcUrl = 'http://localhost:8545';
    ProjectConstants.explorerUrl = 'http://localhost:8545';
}

export default ProjectConstants;